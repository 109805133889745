import * as React from 'react';
import { ErrorBoundary } from './util/ErrorBoundary';
import { Route, Switch } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { Login } from './pages/login/Login';
import asyncComponent from '@7rulnik/react-loadable';
import { makeStyles } from '@material-ui/styles';
import { ROUTE_CARTEIRAS_IMPRESSAS, ROUTE_CARTEIRAS_PENDENTES, ROUTE_RELATORIOSV2 } from './RoutesConstants';

const LazyRelatorioDetalhado = React.lazy(() =>
    import('./pages/relatorioDetalhado/RelatorioDetalhado').then((module) => ({ default: module.RelatorioDetalhado }))
);

export function Routes() {
    return (
        <div>
            <ErrorBoundary>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                        path="/layouts"
                        component={asyncComponent({
                            loader: () => import('./pages/layouts/layoutsRoutes').then((x) => x.LayoutRoutes),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />
                    <Route
                        path="/usuarios"
                        component={asyncComponent({
                            loader: () => import('./pages/usuarios/UsuariosRoutes').then((x) => x.UsuariosRoutes),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />
                    <Route
                        path="/entidades"
                        component={asyncComponent({
                            loader: () => import('./pages/entidades/EntidadesRoutes').then((x) => x.EntidadesRoutes),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />

                    <Route
                        path="/relatorios"
                        component={asyncComponent({
                            loader: () => import('./pages/reports/Reports').then((x) => x.Reports),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />

                    <Route
                        path="/relatoriosV2"
                        component={asyncComponent({
                            loader: () => import('./pages/reports/ReportsV2').then((x) => x.ReportsV2),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />

                    <Route
                        exact
                        path="/authorities"
                        component={asyncComponent({
                            loader: () => import('./pages/authorities/Authorities').then((x) => x.Authorities),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />
                    <Route
                        exact
                        path="/authorities/:id/permissions"
                        component={asyncComponent({
                            loader: () => import('./pages/authorities/AuthoritiesPermissionsListEdit').then((x) => x.AuthoritiesPermissionsListEdit),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />
                    <Route
                        exact
                        path="/authorities/add"
                        component={asyncComponent({
                            loader: () => import('./pages/authorities/AuthoritiesForm').then((x) => x.AuthoritiesForm),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />
                    <Route
                        exact
                        path="/authorities/:id/edit"
                        component={asyncComponent({
                            loader: () => import('./pages/authorities/AuthoritiesForm').then((x) => x.AuthoritiesForm),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />
                    <Route
                        exact
                        path={['/', '/carteiras', '/carteiras/*']}
                        component={asyncComponent({
                            loader: () => import('./pages/carteiras/CarteirasRoutes').then((x) => x.CarteirasRoutes),
                            loading: () => <h1>Carregando...</h1>,
                        })}
                    />

                    <React.Suspense fallback={<h1>Rendering...</h1>}>
                        <Route exact path={ROUTE_RELATORIOSV2} component={LazyRelatorioDetalhado} />
                    </React.Suspense>
                </Switch>
            </ErrorBoundary>
        </div>
    );
}
