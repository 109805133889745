import { autorun, computed, makeAutoObservable, observable } from 'mobx';
import { isUserLogged } from '../util/utils';

export class Variables {
    @observable private _title: string = 'Minha Carteirinha <3';

    @observable private _isLoggedIn: boolean = isUserLogged();

    constructor() {
        makeAutoObservable(this);
    }

    @computed
    get title(): string {
        return this._title;
    }

    // não precisa do decorator @action, já é uma ação automaticamente
    // https://mobx.js.org/refguide/computed-decorator.html#setters-for-computed-values
    set title(title: string) {
        this._title = title;
    }

    @computed
    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    set isLoggedIn(logged: boolean) {
        this._isLoggedIn = logged;
    }
}
