import * as React from 'react';
import { Login } from '../pages/login/Login';
import { withRouter } from 'react-router-dom';
import { Variables } from '../stores/Variables';
import { useContext } from 'react';
import { VariablesContext } from '../components/App';

export function Logged(props: { children: React.ReactElement }) {
    const variables = useContext(VariablesContext);

    if (variables.isLoggedIn) {
        return props.children;
    } else {
        return <Login />;
    }
}
