import { computed, makeAutoObservable, observable } from 'mobx';

export class CellConfig {
    constructor() {
        makeAutoObservable(this);
    }

    @observable private __open: boolean = false;

    @computed
    get open(): boolean {
        return this.__open;
    }

    set open(open: boolean) {
        this.__open = open;
    }

    toggleOpen = () => {
        this.open = !this.open;
    };
}
