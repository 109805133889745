import * as React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Theme, Divider } from '@material-ui/core';
import { Link, Route } from 'react-router-dom';
import axios from 'axios';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import CreditCard from '@material-ui/icons/CreditCard';
import PeopleIcon from '@material-ui/icons/People';
import SecurityIcon from '@material-ui/icons/Security';
import PowerOffIcon from '@material-ui/icons/PowerSettingsNew';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ReportIcon from '@material-ui/icons/Report';
import SubtitleIcon from '@material-ui/icons/Subtitles';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import { hasPermission, isUserLogged } from '../../util/utils';
import DescriptionIcon from '@material-ui/icons/Description';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ImportIcon from '@material-ui/icons/ImportExport';
import PersonIcon from '@material-ui/icons/Person'
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { RouteProps } from '../../enum/RouterTypes';
import { ROUTE_CARTEIRAS_IMPRESSAS, ROUTE_CARTEIRAS_PENDENTES } from "../../RoutesConstants";

const useStyles = makeStyles((theme: Theme) => ({
    nested: {
        paddingLeft: theme.spacing(4)
    }
}));

interface CustomMenuLinkProps {
    label: string | React.ReactNode;
    to: string | string[];
    Icon: any;
}

const useStylesMenuLink = makeStyles((theme: Theme) => ({
    active: {
        color: theme.palette.primary.main
    },
    notActive: {
        color: theme.palette.text.primary
    }
}));

const CustomMenuLink = ({ label, to, Icon }: CustomMenuLinkProps) => {
    const classes = useStylesMenuLink();

    return (
        <Route
            path={to}
            exact={true}
            children={({ match }: RouteProps) => (
                <Link to={Array.isArray(to) ? to[0] : to}>
                    <ListItem button>
                        <ListItemIcon>
                            <Icon
                                className={clsx({
                                    [classes.active]: match
                                })}
                            />
                        </ListItemIcon>
                        <ListItemText primary={label} classes={{ primary: match ? classes.active : classes.notActive }} />
                    </ListItem>
                </Link>
            )}
        />
    );
};

export const AppBarMenuItems = () => {
    const classes = useStyles();
    const logged: boolean = isUserLogged();

    return (
        <>
            {!logged && (
                <CustomMenuLink label='Entrar' to={'/'} Icon={PersonIcon}/>
            )}
            {logged && (
                <>
                    <CustomMenuLink to={['/carteiras', ROUTE_CARTEIRAS_PENDENTES]} label="Pendentes" Icon={CreditCard} />
                    <CustomMenuLink to={ROUTE_CARTEIRAS_IMPRESSAS} label="Impressas" Icon={CreditCard} />
                </>
            )}

            {hasPermission('all') && (
                <>
                    <CustomMenuLink to="/entidades" label="Entidades" Icon={FolderSharedIcon} />
                    <CustomMenuLink to="/layouts" label="Layouts" Icon={CreditCard} />
                    <CustomMenuLink to="/usuarios" label="Usuários" Icon={PeopleIcon} />
                    <CustomMenuLink to="/authorities" label="Niveis de usuário" Icon={SecurityIcon} />
                </>
            )}

            {hasPermission('relatorios__ver_relatorios_sobre_o_nivel__id_', true) && (
                <CustomMenuLink to="/relatorios" label="Relatórios" Icon={AssessmentIcon} />
            )}

            {logged && (
                <>
                    <Divider />
                    <List>{logoutMenuItem}</List>
                </>
            )}
        </>
    );
};

const logout = () => {
    axios
        .post('/logout', {}, { maxRedirects: 0 })
        .then(() => {
            window.location.href = '/';
        })
        .catch(() => {
            window.location.href = '/';
        });
};

export const logoutMenuItem = (
    <ListItem button onClick={logout}>
        <ListItemIcon>
            <PowerOffIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
    </ListItem>
);
