import * as React from 'react';
import axios from 'axios';
import { Paper, FormControl, Button, TextField, Typography, Input, InputLabel, InputAdornment, Theme } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { runInAction } from 'mobx';
import { isUserLogged } from '../../util/utils';
import { VariablesContext } from '../../components/App';

const loginStyles = (theme: Theme) =>
    createStyles({
        root: {
            minWidth: 300,
            maxWidth: 300,
            margin: '0 auto',
            boxShadow: '0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);',
        },
        textField: {
            width: '100%',
        },
        login: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            padding: '15px 0',
        },
    });

class LoginClass extends React.Component<WithStyles<typeof loginStyles>> {
    static contextType = VariablesContext;

    state = {
        name: '',
        password: '',
        showPassword: false,
        error: false,
    };

    // @ts-ignore
    handleChange = (name) => (event) => {
        this.setState({
            // @ts-ignore
            [name]: event.target.value,
        });
    };

    // @ts-ignore
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClickShowPasssword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    // @ts-ignore
    handleClickLogin = (event) => {
        event.preventDefault();

        const params = new URLSearchParams();
        params.append('username', this.state.name);
        params.append('password', this.state.password);

        axios
            .post('/login', params)
            .then((response) => {
                window.location.href = '/';
                if (this.context.isLoggedIn !== isUserLogged()) {
                    runInAction(() => {
                        this.context.isLoggedIn = isUserLogged();
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({ error: error.response.data });
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error no login, fatal', error.message);
                }
            });
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.root} style={{ textAlign: 'center', marginTop: 60 }}>
                    <div className={classes.login}>
                        <Typography style={{ color: 'white' }} variant="h6">
                            Login
                        </Typography>
                    </div>

                    <Paper style={{ padding: '0 20px 20px' }}>
                        <form onSubmit={this.handleClickLogin}>
                            <TextField
                                type="email"
                                error={this.state.error !== false}
                                id="username"
                                label="Usuário"
                                className={classes.textField}
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                margin="normal"
                            />
                            <FormControl className={classes.textField}>
                                <InputLabel htmlFor="password">Senha</InputLabel>
                                <Input
                                    error={this.state.error !== false}
                                    id="password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    onChange={this.handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleClickShowPasssword} onMouseDown={this.handleMouseDownPassword}>
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            <div style={{ textAlign: 'right', marginTop: 20 }}>
                                <Button
                                    disabled={this.state.name === '' || this.state.password === '' || this.state.password.length < 8}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleClickLogin}
                                >
                                    Entrar
                                </Button>
                            </div>
                        </form>
                    </Paper>
                </div>
            </>
        );
    }
}

export const Login = withStyles(loginStyles)(LoginClass);
