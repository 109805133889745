import * as React from 'react';
import { Logged } from '../util/Logged';
import { Routes } from '../Routes';
import { pink, deepPurple } from '@material-ui/core/colors';
import { createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import { Variables } from '../stores/Variables';
import { createContext } from 'react';
import { CellConfig } from '../stores/CellConfig';
import { carteirasStore, CarteirasStore } from '../stores/CarteirasStore';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import { TopBar } from './app_bar/TopBar';
import { StyleSheetManager, ThemeProvider as StyledThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { autorun } from 'mobx';
import styled from 'styled-components';
import theme from 'theme.macro';
const queryClient = new QueryClient();

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            light: deepPurple['A400'],
            main: deepPurple['A700'],
            dark: deepPurple['900'],
        },
        secondary: {
            light: pink[300],
            main: pink[500],
            dark: pink[700],
        },
    },
});

export const ThemeContext = createContext(muiTheme);

const varsStore = new Variables();
const cellConfigStore = new CellConfig();
export const VariablesContext = createContext(varsStore);
export const CellConfigContext = createContext(cellConfigStore);
export const CarteirasStoreContext = createContext(carteirasStore);

autorun(() => {
    document.title = varsStore.title;
});

const AppContainer = styled.div`
    width: 100%;
    padding: ${theme.spacing(2)}px;
`;

export const App = () => (
    <QueryClientProvider client={queryClient}>
        <StylesProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
                <StyleSheetManager disableVendorPrefixes>
                    <StyledThemeProvider theme={muiTheme}>
                        <BrowserRouter>
                            <TopBar>
                                <Logged>
                                    <AppContainer>
                                        <Routes />
                                    </AppContainer>
                                </Logged>
                            </TopBar>
                            <ToastContainer />
                        </BrowserRouter>
                    </StyledThemeProvider>
                </StyleSheetManager>
            </ThemeProvider>
        </StylesProvider>
    </QueryClientProvider>
);
