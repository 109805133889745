export const ROUTE_CARTEIRAS_PENDENTES = '/carteiras/pendentes';

export const ROUTE_CARTEIRAS_IMPRESSAS = '/carteiras/impressas';

export const ROUTE_EDITAR_CARTEIRA = '/editar-carteira/:uuid';

export const ROUTE_EVENTOS = '/eventos';

export const ROUTE_CONFIGURACOES = '/configuracoes';

export const ROUTE_RELATORIOS = '/relatorios';

export const ROUTE_SINCRONIZAR = '/sincronizar';

export const ROUTE_LAYOUTS_LISTV2 = '/layoutsv2';

export const ROUTE_CARTEIRA_FORM = '/carteira/form';
export const ROUTE_CARTEIRA_FORM_EDIT = '/carteira/form';

export const ROUTE_LAYOUT_FORM = '/layout/form';
export const ROUTE_LAYOUT_FORM__EDIT_LAYOUT = '/layout/form/:uuid';

export const ROUTE_SELECT_CARTEIRA_LAYOUT = '/carteiras/add';

// todo manter o relatório antigo, vai ser útil pra pagar os digitadores
// pagar os pontos e manter o relatório das outras forças limitadas
export const ROUTE_RELATORIOSV2 = '/beta';
