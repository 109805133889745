import React from 'react';
import 'reflect-metadata';
import { render } from 'react-dom';
import { App } from './components/App';
import axios from 'axios';
import { installDefaultSessionConfig } from './pages/front/SessionConfig';
import { userStore } from './stores/User';
import './customReset.css';

installDefaultSessionConfig();

(async () => {
    const response = await axios.get('/api/prepare-future-post-requests');

    if ('cms-user-serialized' in response.headers) {
        const userSerialized = JSON.parse(response.headers['cms-user-serialized']);
        userStore.email = userSerialized.username;
        userStore.permissions = userSerialized.authorities;
    }
    // a aplicação acabou de ser iniciada, precisamos do token anti-csrf para conseguir fazer requests do tipo POST
    render(<App />, document.getElementById('root'));

    window.setInterval(() => {
        axios.get('/api/prepare-future-post-requests');
    }, 600 * 10000); // de 100 em 100 minutos renova o token, ou pelo menos deveria...
})();
