// @ts-nocheck
import * as Cookies from "js-cookie";
import {userStore} from "../stores/User";

export const assignRef = <T>(type: T) => (name: keyof T) => (node: any) => {
	if (node != null) {
		type[name] = node;
	}
};

export function hasPermission(permission: string, relaxed = false): boolean {
	if (relaxed) {
		return (
			'all' in userStore.permissions ||
			Object.keys(userStore.permissions).filter((item: string) => {
				if (item.startsWith("relatorios__ver_relatorios_sobre_o_nivel__id_")) {
					return item;
				}
			}).length > 0
		);
	}

	return 'all' in userStore.permissions || permission in userStore.permissions;
}

export function isUserLogged(): boolean {
	return Cookies.get("logged") === "true";
}

export function b64toBlob(b64Data: any, sliceSize?: any): Blob {
	const block = b64Data.split(";");
	const contentType = block[0].split(":")[1];
	const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

	sliceSize = sliceSize || 512;

	const byteCharacters = atob(realData);
	var byteArrays: any = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
}

export function onlyLetters(value: any) {
	const re: RegExp = new RegExp("\\d", "g");
	return re.test(value) ? false : true;
}

export const upperCase = (string: string) => string.toUpperCase();

export function isGrafica () {
	return userStore.email?.toLowerCase().startsWith("grafica") === true;
}