import { makeAutoObservable, observable } from 'mobx';

export type UserPermission = {
    [key: string]: boolean;
};

class UserObservable {
    @observable permissions: UserPermission = {};
    @observable email: string = '';

    constructor() {
        makeAutoObservable(this);
    }
}

export const userStore = new UserObservable();
