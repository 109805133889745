import * as React from 'react';
import { Checkbox, Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';

interface Props {
    open: boolean;
    onClose: any;
}

export interface State {
    cpf: boolean;
    nome: boolean;
    instituicao: boolean;
    curso: boolean;
    matricula: boolean;
    dataDeNascimento: boolean;
    rg: boolean;
    cidade: boolean;
    createdAt: boolean;
    changedAt: boolean;
}

export const titulos: any = {
    cpf: 'CPF',
    nome: 'Nome',
    instituicao: 'Instituição',
    curso: 'Curso',
    matricula: 'Matrícula',
    dataDeNascimento: 'Data de nascimento',
    rg: 'RG',
    cidade: 'Cidade',
    createdAt: 'Data de criação',
    changedAt: 'Data da última modificação',
};

export function setCellConfig(key: keyof State, value: string) {
    localStorage.setItem(`exibir_celula__${key}`, value);
}

export function getCellConfig(key: keyof State): boolean {
    return JSON.parse(localStorage.getItem(`exibir_celula__${key}`) as string) === true;
}

export class CellConfigDialog extends React.Component<Props, State> {
    state: State = {
        cpf: getCellConfig('cpf'),
        nome: getCellConfig('nome'),
        instituicao: getCellConfig('instituicao'),
        curso: getCellConfig('curso'),
        matricula: getCellConfig('matricula'),
        dataDeNascimento: getCellConfig('dataDeNascimento'),
        rg: getCellConfig('rg'),
        cidade: getCellConfig('cidade'),
        createdAt: getCellConfig('createdAt'),
        changedAt: getCellConfig('changedAt'),
    };

    handleToggle = (stateKey: keyof State) => () => {
        // @ts-ignore
        this.setState((prevState) => {
            const newValue = !prevState[stateKey];
            setCellConfig(stateKey, JSON.stringify(newValue));
            return { [stateKey as keyof State]: newValue };
        });
    };

    render() {
        return (
            <div style={{ width: 600 }}>
                <Dialog fullWidth onClose={this.props.onClose} aria-labelledby="simple-dialog-title" open={this.props.open}>
                    <DialogTitle id="simple-dialog-title">Exibir dados da carteira</DialogTitle>
                    <div>
                        <List>
                            {Object.keys(titulos).map((key: any) => (
                                <ListItem
                                    key={key}
                                    role={undefined}
                                    // dense
                                    button
                                    onClick={this.handleToggle(key)}
                                    // className={classes.listItem}
                                >
                                    <Checkbox checked={this.state[key as keyof State] === true} tabIndex={-1} disableRipple />
                                    <ListItemText primary={titulos[key as any]} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Dialog>
            </div>
        );
    }
}
