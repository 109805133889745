import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, Hidden, CssBaseline } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AppBarMenuItems } from './AppBarMenuItems';
import { observer } from 'mobx-react-lite';
import { VariablesContext } from '../../components/App';
import { makeStyles } from '@material-ui/styles';
import { useContext, useState } from 'react';
import FitText from '@plutonium-js/react-fit-text';
import { userStore } from '../../stores/User';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
    '@global': {
        body: {
            backgroundColor: '#dcdcdc',
        },
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
    },
}));

const SideList = () => {
    return (
        <div>
            <Typography variant="h6" style={{ padding: 4 }}>
                <FitText>{userStore.email}</FitText>
            </Typography>
            <List>
                <AppBarMenuItems />
            </List>
        </div>
    );
};

export const TopBar = observer((props: { children: React.ReactElement }) => {
    const variables = useContext(VariablesContext);
    const classes = useStyles();
    const [showDrawer, setShowDrawer] = useState(false);

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton color="inherit" aria-label="Open drawer" onClick={toggleDrawer} className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" noWrap>
                        {variables.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden lgUp={true} implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={'left'}
                        open={showDrawer}
                        onClose={toggleDrawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <SideList />
                    </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <SideList />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
});
