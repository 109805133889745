import * as React from 'react';

interface Props {
    children: any
}

interface State {
    hasError: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
    state: State = {
        hasError: false
    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
		this.setState({ hasError: true }, () => {
			alert("Ocorreu um erro inesperado, a pagina será recarregada");
			// location.reload();
		});
	}

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Algo deu muito errado.</h1>;
        }

        return this.props.children;
    }
}
