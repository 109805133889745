import {setCellConfig} from "./CellConfigDialog";

const defaultCellValues: any = {
	cpf: true,
	nome: true,
	instituicao: true,
	curso: true,
	matricula: false,
	dataDeNascimento: false,
	rg: false,
	cidade: true,
	createdAt: false,
	changedAt: false,
};

export function installDefaultSessionConfig() {
	Object.keys(defaultCellValues).forEach((key: any) => {
		setCellConfig(key, defaultCellValues[key])
	});
}
