import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { ICarteiraListed } from './ICarteiraListed';

interface IDictionary<TValue> {
    [key: number]: TValue;
}

export class CarteirasStore {
    private currentIdsSerialized: string = '';

    @observable __carteiras: ICarteiraListed[] = [];
    @observable selected: IDictionary<boolean> = {};

    constructor() {
        makeAutoObservable(this);
    }

    get carteiras(): ICarteiraListed[] {
        return this.__carteiras;
    }

    set carteiras(carteiras: ICarteiraListed[]) {
        // calcula os ids antigos somente antes de atribuir novos valores
        const newIds = JSON.stringify(carteiras.map((c: any) => c.id));
        if (newIds !== this.currentIdsSerialized) {
            this.__carteiras = carteiras;
            this.currentIdsSerialized = newIds;
            this.resetSelected();
        }
    }

    @computed
    isAllSelected(): boolean {
        return this.carteiras.length !== 0 && this.carteiras.length === Object.keys(this.selected).length;
    }

    @computed
    isSomeSelected(): boolean {
        return Object.values(this.selected).some((value) => value === true);
    }

    select = (id: number) => () => {
        runInAction(() => {
            const isAlreadyChecked = this.selected[id] === true;
            if (isAlreadyChecked) {
                delete this.selected[id];
            } else {
                this.selected[id] = true;
            }
        });
    };

    selectAll = action(() => {
        let allChecked = this.isAllSelected();

        if (allChecked) {
            this.selected = {};
        } else {
            const allIds = this.carteiras.map((c: any) => c.id);
            this.selected = allIds.reduce((ac, a) => ({ ...ac, [a]: true }), {});
        }
    });

    resetSelected = action(() => {
        this.selected = {};
    });
}

export const carteirasStore = new CarteirasStore();
